<template>
  <div>
    <Breadcrumbs title="Teacher Settlement Report" main="Reports"/>
    <div class="container-fluid" v-if="loaded">
      <div class="card-body" id="print">

        <b-row>
          <b-col cols="12" md="4" xl="2">Institute</b-col>
          <b-col cols="12" md="8" xl="10">: {{settlement.institute.name}}</b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" xl="2">Lecturer</b-col>
          <b-col cols="12" md="8" xl="10">: {{ settlement.user.first_name }} {{ settlement.user.last_name }}</b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" xl="2">Settlement ID</b-col>
          <b-col cols="12" md="8" xl="10">: {{settlement.id}}</b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" xl="2">Last Settlement</b-col>
          <b-col cols="12" md="8" xl="10">: {{ last_settlement_id || 'N/A' }}</b-col>
        </b-row>
        <b-row v-show="false">
          <b-col cols="12" md="4" xl="2">Date / Time</b-col>
          <b-col cols="12" md="8" xl="10">: {{ moment(settlement.created_at).format('lll') }}</b-col>
        </b-row>
        <b-row v-show="false">
          <b-col cols="12" md="4" xl="2">Generated By</b-col>
          <b-col cols="12" md="8" xl="10">: {{ settlement.creator.first_name }} {{ settlement.creator.last_name }}</b-col>
        </b-row>

        <div class="table-responsive datatable-vue">
          <b-table
              ref="table"
              show-empty
              selected-variant="success"
              :items="tableRecords"
              :fields="tableFields"
              :filter="filter"
           >
            <template #cell(created_at)="row">
              <p class="py-0 my-1">{{ moment(row.item.created_at).format('ll') }}</p>
              <p class="py-0 my-1">{{ moment(row.item.created_at).format('h:mm:ss A') }}</p>
            </template>
            <template #cell(invoice)="row">
              <p class="py-0 my-1">{{ row.item.invoice_id }}</p>
              <p class="py-0 my-1">{{ moment(row.item.class_detail.payment_month).format('YYYY-MMM') }}</p>
            </template>
            <template #cell(student_details)="row">
              <p class="py-0 my-1">{{row.item.user.nic}}</p>
              <p class="py-0 my-1">{{row.item.user.first_name}} {{row.item.user.last_name}}</p>
            </template>
            <template #cell(settle_state)="row">
              {{ row.item.status === 0 ? "YES" : "NO" }}
            </template>
          </b-table>
        </div>


      </div>
      <div class="card-footer text-center">
        <b-button @click="print('print')" class="text-uppercase" variant="success">Print</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import printJS from 'print-js'
import {mapState} from "vuex";
import {checkUserPermissions} from "@/plugins/functions";
import CRUD_API from "@/services/crud";
export default {
  name: "settlements_show",
  computed: mapState({ user: state => JSON.parse(state.auth.user) }),
  mounted() {
    this.fetchData()
  },
  data() {
    return {
      loaded: true,
      base_url: '/api/backend/pages/',

      filter: '',
      totalRows: 0,
      perPage: 0,
      currentPage: 1,
      lastPage: 1,
      tableRecords: [],
      tableFields: [
        { key: 'created_at', label: 'Date / Time', sortable: false },
        { key: 'student_details', label: 'NIC', sortable: false },
        { key: 'invoice', label: 'Invoice', sortable: false, class: 'text-right' },
        { key: 'class_detail.name', label: 'Class Name', sortable: false },
        { key: 'class_detail.class_fee', label: 'Class Fee', sortable: false, class: 'text-right' },
        { key: 'discount_value', label: 'Discount', sortable: false, class: 'text-right' },
        { key: 'amount', label: 'Net Amount', sortable: false, class: 'text-right' },
        { key: 'settle_state', label: 'Settled', sortable: false, class: 'text-right' },
      ],
      settlement: null,
      last_settlement_id: 0,
    }
  },
  methods: {
    checkUserPermissions,
    clearTableData() {
      this.loaded = false
      this.tableRecords = []
      this.totalRows = 0
      this.currentPage = 1
    },
    async fetchSettlement(){
      await CRUD_API.show(this.base_url + 'settlements', this.$route.params.settlementId)
          .then((response) => {
            this.last_settlement_id = response.data.data.last_settlement_id
            this.settlement = response.data.data.data
            // this.totalRows = response.data.data.payments_count
          }).catch((errors) => {
            console.log("LOADING_SETTLEMENTS", errors)
          }).finally(() => {
            // this.$refs.table.refresh()
          })
    },
    async fetchPayments(){
      await CRUD_API.index(this.base_url + 'payments', {
        url: this.base_url + 'payments',
        params: {
          per_page: this.perPage,
          page: this.currentPage,
          settlement_id: this.$route.params.settlementId,
          settlement_type: 0
        }
      }).then((response) => {
        this.totalRows = response.data.data.total
        this.lastPage = response.data.data.last_page
        this.tableRecords = this.tableRecords.concat(response.data.data.data)
        this.loaded = true
      }).catch((errors) => {
        console.log("LOADING_SETTLEMENTS", errors)
      }).finally(() => {
        // this.$refs.table.refresh()
      })
    },
    async fetchData() {
      this.loaded = false
      await this.clearTableData()
      await this.fetchSettlement()
      await this.fetchPayments()
      clearTimeout()
      await setInterval(() => {
        this.loaded = true
        if(this.currentPage <= this.lastPage) {
          this.currentPage += 1
          this.fetchPayments()
        } else {
          clearTimeout()
        }
      }, 2000)
    },
    print(el) {
      return printJS({
        printable: el,
        type: 'html',
        targetStyles: ['border', 'padding', 'border-bottom', 'border-top', 'background'],
        showModal: true,
        modalMessage: "Retrieving Document...",
        documentTitle: "Teacher Settlement Report",// + this.$route.params.settlementId,// + " (" + this.moment().format('l') + ")",
        onPdfOpen: true,
        base64: true
      });
    }
  }
}
</script>

<style scoped>
@media print {
  .table {
    border: 1px solid #0c0e13;
  }
}
</style>